$sidebar-width: 300px;

.main-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: $sidebar-width;
  background-color: rgba(53, 58, 63, 0.9);
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;

  .input-container {
    position: relative;
    display: flex;

    input {
      margin: 10px 20px;
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 24px;
      padding: 24px 42px 24px;
      color: #fff;
      font-size: 16px;
    }

    &::before {
      position: absolute;
      top: 24px;
      left: 40px;
      font-family: 'FontAwesome';
      content: '\f002';
      color: #fff;
    }
  }

  .filter-date-container {
    .react-datepicker-wrapper {
      width: 100%;
      .react-datepicker__input-container {
        padding: 0px 10px;
        margin-bottom: 10px;
        .task-filter-date {
          width: 100%;
          font-size: 15px;
          padding: 8px;
          border-radius: 5px;
          border: 0;
          background-color: rgba(255, 255, 255, 0.2);
          color: #fff;
        }
        &::after {
          position: absolute;
          top: 5px;
          right: 20px;
          font-family: 'FontAwesome';
          font-size: 18px;
          content: '\f073';
          color: #fff;
        }
      }
    }
  }

  .nav-tabs {
    display: flex;
    border-bottom: none;

    .nav-item {
      flex: 1;
      text-transform: uppercase;
      text-align: center;
      cursor: pointer;

      .nav-link {
        padding: 8px 20px;
        border: none;
        color: #fff;
        font-weight: 600;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 0;

        .badge {
          padding: 3px 4px;
          font-size: 10px;
          background-color: #fff;
          border: 1px solid #fff;
        }

        &.active {
          background-color: #337feb;
        }

        &:not(.active) {
          .badge {
            opacity: 0.6;
            color: #fff;
            background-color: transparent;
          }
        }
      }
    }
  }
}

.task-list {
  padding-left: 12px;

  &-header {
    cursor: pointer;
    position: relative;

    .toggle-icon {
      font-size: 12px;
      color: #fff;
      position: absolute;
      left: -10px;
      top: 2px;
    }
  }

  &-header-content,
  &-progress {
    margin-left: 16px;
  }

  &-progress {
    .progress {
      height: 3px;
      background-color: rgba(98, 98, 98, 0.7);
    }

    .progress-bar {
      background-color: #00ff74;
    }
  }

  &-header-content {
    flex: 1 1;
    padding-bottom: 4px;
    color: #fff;

    .title {
      font-size: 14px;
      font-weight: 500;
    }

    .subtitle {
      font-size: 10px;
    }

    .focus-icon:not(.visible) {
      opacity: 0.2;
    }
  }
}

.task-item {
  margin-top: 14px;
  cursor: pointer;

  &-icon {
    font-size: 20px;
    &__address {
      margin-right: 2px;
      font-size: 12px;
    }
  }

  &-title {
    color: #fff;
    font-size: 15px;
  }

  &-subtitle {
    color: #d6d6d6;
  }

  &-subtitle-2 {
    color: #a0a0a0;
    font-size: 14px;
  }

  &-subtitle-3 {
    font-size: 10px;
  }
}
