/* noUiSlider
http://refreshless.com/nouislider/
--------------------------------------------------
*/
.noUi-target{
	border-radius:0px;
	border: 0;
	box-shadow: none;
	&.bg-complete{
		.noUi-connect{
			background-color: $color-complete
		}
	}
	&.bg-success{
		.noUi-connect{
			background-color: $color-success
		}
	}
	&.bg-warning{
		.noUi-connect{
			background-color: $color-warning
		}
	}
	&.bg-danger{
		.noUi-connect{
			background-color: $color-danger
		}
	}
	&.bg-info{
		.noUi-connect{
			background-color: $color-info
		}

	}
	&.bg-primary{
		.noUi-connect{
			background-color: $color-primary
		}
		
	}
	&.noUi-connect{
		box-shadow: none;
	}
}
.noUi-handle{
	border-radius:999px;
	box-shadow:none;
	&:before, &:after{
		display:none;
	}
}
.noUi-horizontal {
	height: 4px;
	.noUi-handle{
	width: 18px;
	height: 18px;
	left: -15px;
	border: 1px solid mix($color-master, #fff, 23%);
	top: -7px;
	@include transition(all 0.2s ease);
		&:hover{
			border: 1px solid mix($color-master, #fff, 40%);
		}
		&:active{
			@include scale(1.3);
			border: 1px solid mix($color-master, #fff, 23%);
		}
		&:focus{
			@include scale(1.3);
			border: 1px solid mix($color-master, #fff, 23%);
		}
	}
}
.disable-hover-scale{
	.noUi-handle{
		&:active{
			@include scale(1);
		}
		&:focus{
			@include scale(1);
		}		
	}
}
.vertical-slider{
	height: 150px;
}
.noUi-vertical {
	width: 4px;
	.noUi-handle{
	width: 18px;
	height: 18px;
	border: 1px solid mix($color-master, #fff, 23%);
	@include transition(all 0.2s ease);
		&:hover{
			border: 1px solid mix($color-master, #fff, 40%);
		}
		&:active{
			@include scale(1.3);
			border: 1px solid mix($color-master, #fff, 23%);
		}
		&:focus{
			@include scale(1.3);
			border: 1px solid mix($color-master, #fff, 23%);
		}
	}
}
.noUi-base{
	padding: 5px 0;
}
.noUi-origin{
	border-radius: 0px;
	bottom: 5px;
}
.noUi-connect{
	box-shadow:none;
}
.noUi-background{
	background:$slider-background-color !important;
	box-shadow: none;
}