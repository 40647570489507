
/* Quick View
------------------------------------
*/
.quickview-wrapper{
    position:fixed;
    right:-$layout-right-quickview-width;
    top:0;
    width:$layout-right-quickview-width;
    background:#fff;
    bottom:0;
    z-index:1000;
    box-shadow:0 0 9px rgba(191,191,191,.36);
    border-left: 1px solid rgba(222,227,231,.56);
    -webkit-transition: -webkit-transform 400ms cubic-bezier($menu-bezier);
    transition: transform 400ms cubic-bezier($menu-bezier);
    -webkit-backface-visibility: hidden;
    -ms-backface-visibility:hidden;
    -webkit-perspective: 1000;
    &.open{
        @include translate3d(-100%,0,0);
    }
    .quickview-toggle{
        position: absolute;
        right: 12px;
        top: 2px;
        color: $color-menu-light;
        padding: 6px;
        opacity: 0.4;
        &:hover{
            opacity: 1;
        }
    }
    .nav-tabs{
        /* Quickview Tabs */
        background-color: $color-menu;
        position: relative;
        padding: 0 43px;
        & > li{
            & > a{
                padding:11px;
                color: $color-menu-light;

                border: 0;
                text-align: center;
                font-size: 11px;
                font-weight: bold;
                min-width: 62px;
                line-height: 1.4;
                & > span{
                    opacity: 0.6;
                }
                &:hover{
                    & > span{
                        color: $color-menu-light;
                        opacity: 1;
                    }
                }
                &.active{
                    &:hover, &:focus{
                        background: transparent;
                        color: mix($color-menu-light, #fff, 37%);
                        opacity: 1;
                    }
                }
            }
            &.active{
                & > a, & > a:hover, & > a:focus{
                    background-color: transparent;
                    border: 0;
                    color: mix($color-menu-light, #fff, 37%);
                    opacity: 1;
                }
            }
        }
        ~ .tab-content{
            position:absolute;
            padding: 0;
            left: 0;
            right: 0;
            top: 38px;
            bottom: 0;
            height: auto;
            & > div{
            height:100%;
            }
            ul{
                margin:0;
                padding:0;
                li{
                    list-style:none;
                }
            }
            .quickview-notes{
                /* Quickview Notes */
                background:#fbf9e3;
                a, button{
                    color:#968974;
                }
                .list{
                    position:relative;
                    .toolbar{
                        height:50px;
                        padding:0 25px;
                        ul {
                            margin-top:10px;
                            & > li{
                                display:inline-block;
                                height:auto;
                                a{
                                    height:22px;
                                    line-height:22px;
                                    display:block;
                                    padding:0 5px;
                                    font-size: 14px;
                                    &:hover, &.selected{
                                        background:#968974;
                                        color:#FBF9E3;
                                    }
                                }
                            }
                        }
                        .btn-remove-notes{
                            background:transparent;
                            bottom: 8px;
                            display: block;
                            left: 50%;
                            margin-left: -40%;
                            position: absolute;
                            width: 83%;
                            border:1px solid #968974;
                        }
                    }
                    & > ul {
                        padding:0;
                        li{
                            cursor:pointer;
                            height:42px;
                            padding:0 25px;
                            display:block;
                            clear:both;
                            .left{
                                float:left;
                                width:65%;
                                height:100%;
                                padding-top:9px;
                                .checkbox{
                                    display:none;
                                    float:left;
                                    label{
                                        margin-right:0;
                                        vertical-align:text-top;
                                    }
                                }
                                p{
                                    margin:0;
                                    font-size:13px;
                                    font-weight:bold;
                                    width: 100px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    vertical-align:middle;
                                    display:inline-block;
                                }
                            }
                            .right{
                                font-size:10.5px;
                                text-align:right;
                                width:35%;
                                line-height: 41px;
                                .date{
                                    margin-right:10px;
                                }
                            }
                            &:hover{
                                background:#f4ecd1;
                            }

                        }
                    }
                }
                .note{
                    /* Quickview Note */
                    background-image:url('#{$base-img-url}/notes_lines.png');
                    background-repeat:repeat-y;
                    background-position:27px top;
                    & > div{
                        display:table; height:100%; width:100%;
                    }
                    .toolbar {
                        background:#fcfcfa;
                        height:35px;
                        width:100%;
                        display:table-row;
                        box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.33);
                        & > li{
                            display:inline-block;
                            border-right: 1px solid #EDECEC;
                            float: left;
                            line-height: 37px;
                            padding: 0;
                            text-align: center;
                            width: 40px;
                            height:auto;
                            & > a{
                                color:#a5a5a5;
                                display:block;
                                font-size: 14px;
                                &:hover{
                                    background:#fffaf6;
                                }
                                &.active{
                                    color:#333;
                                    background:#f9f1ea;
                                }
                            }
                        }
                        &:after{
                            position:absolute;
                            content:"";
                            width:100%;
                        }
                    }
                    .body {
                            display:table-row;
                            height:100%;
                            & > div{
                                display:table;
                                height:100%;
                                width: 100%;
                                padding:0 20px 0 45px;
                                white-space: normal;
                            }
                            .top{
                                height:50px;
                                display:table-row;
                                clear:both;
                                line-height:50px;
                                text-align:center;
                                & > a{
                                    float:left;
                                    color:#b0b0a8;
                                    margin-left:10px;
                                }
                                & > span{
                                    font-style:italic;
                                    color:#b0b0a8;
                                    font-size:11px;
                                }
                            }
                            .content{
                                display:table-row;
                                height:100%;
                                form{
                                    height:100%;

                                }
                                .quick-note-editor{
                                    font-size:12px;
                                    &:focus{
                                        outline: none;
                                    }
                                    &::-moz-selection{
                                        background:#fef8ae;
                                    }
                                    &::selection{
                                        background:#fef8ae;
                                    }
                                }

                            }
                    }
                }
            }
        }
    }
}
