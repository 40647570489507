/*------------------------------------------------------------------
[9. Progress Indicators]
*/
.progress {
    height: 4px;
    background-color: fade-out($color-master, .8);
    background-image: none;
    box-shadow: none;
    border-radius: 0;

    margin-bottom: 20px;
    &.transparent {
        background-color: rgba(0, 0, 0, 0.28);
    }
    &.progress-small {
        height: 3px;
    }
}
/* Circular Progress : indeterminate color
------------------------------------
*/
.progress-bar-indeterminate{
    background: url('#{$base-img-url}/progress/progress-bar-master.svg') no-repeat top left;
    width: 100%;
    height: 100%;
    background-size: cover;
    &.progress-bar-primary{
        background-image: url('#{$base-img-url}/progress/progress-bar-primary.svg');
         background-color: transparent;
    }
    &.progress-bar-complete{
        background-image: url('#{$base-img-url}/progress/progress-bar-complete.svg');
         background-color: transparent;
    }
    &.progress-bar-success{
        background-image: url('#{$base-img-url}/progress/progress-bar-success.svg');
         background-color: transparent;
    }
    &.progress-bar-info{
        background-image: url('#{$base-img-url}/progress/progress-bar-info.svg');
         background-color: transparent;
    }
    &.progress-bar-warning{
        background-image: url('#{$base-img-url}/progress/progress-bar-warning.svg');
         background-color: transparent;
    }
    &.progress-bar-danger{
        background-image: url('#{$base-img-url}/progress/progress-bar-danger.svg');
         background-color: transparent;
    }
    &.progress-bar-master{
        background-image: url('#{$base-img-url}/progress/progress-bar-master.svg');
         background-color: transparent;
    }
}
/* Progress Bar
------------------------------------
*/
.progress-bar {
    box-shadow: none;
    border-radius: 0;
    background-color: $color-master;
    background-image: none;
    @include transition(all 1000ms cubic-bezier(0.785, 0.135, 0.150, 0.860));
    -webkit-transition-timing-function: cubic-bezier(0.785, 0.135, 0.150, 0.860);
    -moz-transition-timing-function: cubic-bezier(0.785, 0.135, 0.150, 0.860);
    -ms-transition-timing-function: cubic-bezier(0.785, 0.135, 0.150, 0.860);
    -o-transition-timing-function: cubic-bezier(0.785, 0.135, 0.150, 0.860);
    transition-timing-function: cubic-bezier(0.785, 0.135, 0.150, 0.860);
}
/* Progress Bar : Color Options
------------------------------------
*/
.progress-bar-primary {
    background-color: $color-primary;
    background-image: none;
}
.progress-bar-complete {
    background-color: $color-complete;
    background-image: none;
}
.progress-bar-success {
    background-color: $color-success;
    background-image: none;
}
.progress-bar-info {
    background-color: $color-info;
    background-image: none;
}
.progress-bar-warning {
    background-color: $color-warning;
    background-image: none;
}
.progress-bar-danger {
    background-color: $color-danger;
    background-image: none;
}
.progress-bar-white {
    background-color:  $color-white ;
    background-image: none;
}
.progress-bar-black {
    background-color: $color-black;
    background-image: none;
}
.progress-info .bar, .progress .bar-info {
    background-color: $color-info-darker;
    background-image: none;
}
.progress-warning .bar, .progress .bar-warning {
    background-color: $color-warning-darker;
    background-image: none;
}
.progress-danger .bar, .progress .bar-danger {
    background-color: $color-danger-darker;
    background-image: none;
}
.progress-white .bar, .progress .bar-white {
    background-color: $color-white;
    background-image: none;
}
.progress-success.progress-striped .bar, .progress-striped .bar-success {
    background-color: $color-success;
}
.progress-info.progress-striped .bar, .progress-striped .bar-info {
    background-color: $color-info;
}
/* Circular Progress : indeterminate 
------------------------------------
*/
.progress-circle-indeterminate{
    background: url('#{$base-img-url}/progress/progress-circle-master.svg') no-repeat top left;
    width: 50px;
    height: 50px;
    background-size: 100% auto;
    margin: 0 auto;
    &.progress-circle-warning{
        background-image: url('#{$base-img-url}/progress/progress-circle-warning.svg');
    }
    &.progress-circle-danger{
        background-image: url('#{$base-img-url}/progress/progress-circle-danger.svg');
    }
    &.progress-circle-info{
        background-image: url('#{$base-img-url}/progress/progress-circle-info.svg');
    }
    &.progress-circle-primary{
        background-image: url('#{$base-img-url}/progress/progress-circle-primary.svg');
    }
    &.progress-circle-success{
        background-image: url('#{$base-img-url}/progress/progress-circle-success.svg');
    }
    &.progress-circle-complete{
        background-image: url('#{$base-img-url}/progress/progress-circle-complete.svg');
    }
}
/* Circular Progress 
------------------------------------
*/
.progress-circle {
    $size: 45px;
    $border : 3px;
    display: block;
    height: $size;
    margin: 0 auto;
    position: relative;
    width: $size;
    -webkit-backface-visibility: hidden;
    &.progress-circle-warning{
        .pie .half-circle{
            border-color: $color-warning;
        }
    }
    &.progress-circle-danger{
        .pie .half-circle{
            border-color: $color-danger;
        }
    }
    &.progress-circle-info{
        .pie .half-circle{
            border-color: $color-info;
        }
    }
    &.progress-circle-primary{
        .pie .half-circle{
            border-color: $color-primary;
        }
    }
    &.progress-circle-success{
        .pie .half-circle{
            border-color: $color-success;
        }
    }
    &.progress-circle-complete{
        .pie .half-circle{
            border-color: $color-complete;
        }
    }
    &.progress-circle-thick{
        .pie .half-circle, .shadow{
            border-width: 5px;
        }
    }
    .pie {
        clip: rect(0, $size, $size, $size / 2);
        height: $size;
        position: absolute;
        width: $size;
        .half-circle {
            border: $border solid $color-master;
            border-radius: 50%;
            clip: rect(0, $size / 2, $size, 0);
            height: $size;
            position: absolute;
            width: $size;

        }
    }
    .shadow {
            border: $border solid fade-out(#000, .9);
            border-radius: 50%;
            height: 100%;
            width: 100%;
            box-shadow: none !important;
    }

}