
@mixin xs {
  @media (max-width: 576px) { @content; }
}

@mixin sm {
  @media (min-width: 768px) { @content; }
}

@mixin sm-and-down {
  @media (max-width: 768px) { @content; }
}

@mixin md {
  @media (min-width: 1024px) { @content; }
}

@mixin md-and-down {
  @media (max-width: 1024px) { @content; }
}

@mixin lg {
  @media (min-width: 1200) { @content; }
}
