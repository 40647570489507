$header-size: 60px;
$sidebar-chat-size: 60px;
$secondary-sidebar-size: 300px;

.header {
  height: $header-size;
  padding-left: $sidebar-chat-size;
  right: 0;
  width: auto;

  &:not(.full) {
    right: $secondary-sidebar-width;
  }

  .profile-dropdown-toggle {
    display: flex;
    border: 1px solid transparent;
    &:hover {
      border: 1px solid rgba(98, 98, 98, 0.27);
    }
    > span,
    > i {
      align-self: center;
    }
  }
}

.page-sidebar {
  width: $sidebar-chat-size;
  z-index: 1000;
  left: 0;
  display: block;
  background-color: transparent;

  .sidebar-header {
    padding: 0 !important;
    height: $header-size;

    .btn-link {
      display: flex;
      place-items: center;
      align-items: center;
      justify-content: center;
      height: inherit;
      width: 100%;
    }
  }

  .sidebar-menu {
    display: none;
    height: calc(100vh - #{$header-size});
    background-color: #2b303b;

    @include md {
      display: block;
    }

    &.open {
      display: block;
    }
  }
}

.page-container {
  .page-content-wrapper {
    padding-top: 70px;
  }
}

.open-secondary-btn {
  display: none !important;

  @include md-and-down {
    display: block !important;
  }
}

.secondary-sidebar {
  top: 0 !important;
  visibility: visible !important;

  @include md-and-down {
    &.open {
      display: block;
      visibility: visible;
      right: 0;
      left: auto;
      border-radius: 0;
      top: $header-size !important;
      padding: 14px 0;
    }
  }
}

.menu-items {
  margin: auto !important;
  overflow: inherit !important;
}

.form-group-attached {
  padding-right: 7px;

  > div {
    margin-bottom: 10px;
  }
}

.login-container {
  // This is to keep Term of use and Privacy policy in the same line
  .pull-bottom {
    width: 75%;
  }
}

.sidebar-visible {
  .page-sidebar {
    transform: translate(210px, 0px);
  }
  .header {
    padding-left: 290px;
  }
  .btn-expand {
    display: none;
  }
}

#root {
  .online {
    li {
      .icon-thumbnail {
        background-color: transparent;
        .thumbnail-wrapper {
          float: none;
          line-height: normal;
        }
        .badge {
          position: absolute;
          top: -5px;
          right: 0;
          font-size: 8px;
          width: 15px;
          height: 14px;
          @include border-radius(50%);
          background-color: $color-danger;
          padding-left: 0;
          padding-right: 0;
        }
        .bubble {
          @include border-radius(100%);
          height: 12px;
          width: 12px;
          background-color: $color-danger;
          color: $color-white;
          position: absolute;
          top: 0px;
          float: right;
          right: 0px;
        }
      }
    }
  }
  .sidebar-header {
    padding-right: 0;
    .btn-expand {
      float: right;
      width: 70px;
      height: 60px;
    }
  }
}

.mapControls {
  position: absolute;
  right: $sidebar-width;
  top: 100px;
  padding-top: 10px;
  padding-right: 10px;
}

.mapOptions {
  position: absolute;
  right: $sidebar-width;
  top: 60px;
  padding-top: 10px;
  padding-right: 10px;
}

.icon-thumbnail.mini {
  width: 10px;
  height: 10px;
}

.circular {
  @include border-radius(50%);
  overflow: hidden;
}

.secondary-sidebar {
  .scroll-wrapper {
    height: calc(100% - 70px);
    overflow-y: auto;
  }
  .card-group {
    .card {
      background-color: transparent;
      border: 0 !important;
      .card-body {
        padding: 0;
      }
      .card-header a:not(.btn) {
        padding: 0;
      }
      .card-title > div {
        margin-left: 20px;
        text-transform: none;
        cursor: pointer;
        color: white;
        &:after {
          font: normal normal normal 14px/1 pages-icon;
          content: '\e62c';
          position: absolute;
          top: 25px;
          left: 16px;
          color: $color-master;
        }
        &.collapsed {
          &:after {
            content: '\e630';
          }
        }
        .status {
          color: $color-menu-light;
        }
      }
    }
  }
  .tasks-list {
    padding-left: 20px;
    & > li {
      list-style: none;
      &.active {
        & > a {
          color: $color-complete;
          &:hover {
            color: $color-complete;
          }
          & > .title {
            position: relative;
            &:after {
              background: $color-complete;
              @include border-radius(50%);
              content: '';
              height: 7px;
              position: absolute;
              right: -14px;
              top: 6.5px;
              width: 7px;
            }
          }
        }
      }
      a {
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 14px;
        color: $color-menu-light;
        &:hover {
          color: #fff;
        }
        & > .title {
          color: white;
          i {
            margin-right: 6px;
            opacity: 0.9;
          }
        }
      }
    }
    .task {
      .icon-thumbnail {
        float: left;
        background: transparent;
        .bb-number {
          margin-left: -4px;
          width: 28px;
          height: 28px;
          line-height: 28px;
          display: inline-block;
          // TODO: Remove this later
          // padding: 6px 10px;
          font-size: 12px;
          @include border-radius(8px);
        }
      }
      .address,
      .status {
        line-height: 1.2em;
      }
    }
  }
}

.sidebar-menu {
  overflow-y: auto;
  // height: 100% !important;

  .divider {
    height: 1px;
    width: 40px;
    margin: 12px auto 0px auto;
    border-bottom: 1px solid #3c3c3c;
  }
}

.open-secondary-btn {
  position: absolute;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 4px 24px;
  background-color: #2e3547 !important;
  border-color: transparent !important;
  z-index: 1000;
  top: $header-size;
  right: 0;

  i {
    color: #cacaca;
  }
}

.open-secondary-sidebar {
  .open-secondary-btn {
    right: $secondary-sidebar-size - 1px;
  }
}

/* Override Pages Notification for Login mainly */

body:not(.horizontal-app-menu) {
  .pgn-wrapper[data-position^='top-'] {
    top: 20px;
  }
}

.popover {
  &.chat {
    z-index: 1100;
    width: 375px;
    max-width: 375px;
    height: 700px;
    background: #fff;
    background: -webkit-linear-gradient(#fff, #fff, #f5f6f8);
    background: -moz-linear-gradient(#fff, #fff, #f5f6f8);
    background: linear-gradient(#fff, #fff, #f5f6f8);
    @include border-radius(16px);

    @include xs {
      height: 500px;
    }

    .popover-inner {
      height: 100%;
      display: flex;
      flex-direction: column;
      h3 {
        &.popover-header {
          margin: 0;
          background: transparent;
          line-height: normal;
          border-bottom: none;
          font-size: 16px;
          font-weight: bold;
          color: #454f63;
          .status {
            font-size: 13px;
          }
        }
      }
      .popover-body {
        // align-self: stretch;
        flex: 1 auto;
        overflow: auto;
        div:not(.alert) {
          background-color: transparent;
          border: none;
        }
        textarea {
          margin-left: 0;
          @include border-radius(16px);
          padding: 16px;
          background-color: #fff;
        }
      }
    }
  }
}

$background: #252827;
$color-primary: $color-master-lightest;
$color-light: white;
$color-dim: #6c6d6d;
$spacing: 50px;
$spacing-left: 15px;
$radius: 4px;
$date: 120px;
$dotborder: 4px;
$dot: 7px;
$line: 6px;
$font-title: 'Saira', sans-serif;
$font-text: 'Chivo', sans-serif;
.events-timeline {
  background-color: $color-master-lightest;
  overflow: hidden;
  .list-wrapper {
    max-height: 50vh;
    overflow-y: auto;
    margin-left: -15px;
    padding-left: 15px;
  }
  ul {
    border-left: $line dotted $color-master-light;
    @include border-right-radius($radius);
    color: $color-master;
    font-family: $font-text;
    letter-spacing: 0.5px;
    position: relative;
    line-height: 1.4em;
    font-size: 1.03em;
    padding: 0 $spacing 0 $spacing-left;
    list-style: none;
    text-align: left;
    font-weight: 100;
    h2,
    h3 {
      font-family: $font-title;
      letter-spacing: 1.5px;
      font-weight: 400;
      font-size: 1.4em;
      line-height: 1em;
    }
    .event {
      margin-bottom: 20px;
      position: relative;
      &:last-of-type {
        padding-bottom: 0;
        margin-bottom: 0;
        border: none;
      }
      &:before,
      &:after {
        position: absolute;
        display: block;
        top: 0;
      }
      &:before {
        left: (
            (($date * 0.6) + $spacing + $line + $dot + ($dotborder * 2)) * 1.5
          ) * -1;
        color: fade($color-light, 40%);
        content: attr(data-date);
        text-align: right;
        font-weight: 100;
        font-size: 0.9em;
        min-width: $date;
        font-family: $font-title;
      }
      &:after {
        box-shadow: 0 0 0 $dotborder $color-master-dark;
        left: ($spacing-left + $line + ($dot * -0.05)) * -1;
        background: lighten($background, 5%);
        @include border-radius(50%);
        height: $dot;
        width: $dot;
        content: '';
        top: 8px;
      }
      .event-title {
        font-size: 18px;
        font-weight: bolder;
        color: #2c2c2c;
      }
    }
  }
}

// Select2
.modal-open {
  .select2-container {
    z-index: inherit;
  }

  .select2-dropdown {
    z-index: 1060;
  }
}

.select2-container {
  .select2-selection--single {
    .select2-selection__rendered {
      padding: 0;
      padding-left: 3px !important;
    }
  }
}

.form-group-default {
  & .form-control {
    height: 25px;
  }
}

// standardize input styles when readonly
.form-group-default.readonly,
.readonly,
.form-control.readonly > .PhoneInputInput {
  background-color: #eee;
  cursor: default;
  border: 0 !important;
  .form-control {
    color: #444;
  }
}

/**
 * For address autocomplete
  */

.boltbox-suggestion {
  font-size: 13px;
  line-height: 20px;
  .boltbox-suggestion-title,
  .boltbox-suggestion-detail {
    font-size: 100%;
  }
  .boltbox-suggestion-title {
    font-weight: bold;
  }
}

/**
 * Making sure modal sizes are taking into account
 */

@media (min-width: 992px) {
  .modal {
    .modal-dialog {
      &.modal-lg,
      &.modal-xl {
        width: 900px;
      }
      &.modal-lg {
        max-width: 1024px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .modal {
    .modal-dialog {
      &.modal-xl {
        width: 1140px;
      }
    }
  }
}

.label {
  padding: 0.6em 2em;
  &.label-pill {
    @include border-radius(10rem);
  }
}

table {
  thead {
    background-color: #f5f6fa;
  }
  .number {
    width: 60px;
  }
  .quantity {
    width: 120px;
  }
  .price {
    width: 160px;
  }
}

.card {
  &.route {
    .card-header,
    .card-footer {
      margin-left: 42px;
      background-color: transparent;
      padding: 0.75rem 1.25rem 0.75rem 0rem;
      h6 {
        font-size: 12px;
        margin: 0;
        &:before {
          content: '';
          display: block;
          position: absolute;
          width: 8px;
          height: 8px;
          @include border-radius(4px);
          background-color: #78849e;
          margin-left: -24px;
          margin-top: 8px;
        }
      }
      h5 {
        font-weight: normal;
        font-size: 16px;
        margin: 0;
        color: #454f63;
      }
    }
    .select2-container {
      .select2-selection--single {
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
      }
    }
  }
  .card-header {
    &:before {
      content: '';
      position: absolute;
      margin-left: -22px;
      height: 100%;
      margin-top: 16px;
      border-left: 3px dotted rgba(120, 132, 158, 0.29);
    }
  }
}

.settings {
  margin-left: -25px;
  .nav-item a {
    border-left: 0;
  }
  & > li > a.nav-link {
    padding: 15px 22px;
    &.active {
      color: #1c87af;
      border: none;
    }
    &:hover {
      border: none;
    }
    &:after {
      left: 0;
      right: auto;
    }
  }
  .subtitle {
    text-transform: none;
    font-size: 0.6rem;
  }
  ~ .tab-content {
    width: 100%;
  }
}

.boltbox-progress {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;
}

/* change background and tip color to green */
.driver-info .mapboxgl-popup-content {
  background-color: #daeffd;
  border-color: #2b6a94;
  color: #2b6a94;
  width: 240px;
}

.driver-info .mapboxgl-popup-tip {
  border-left-color: #daeffd;
}

.driver-info .mapboxgl-popup-anchor-right {
  border-left-color: #daeffd;
}

/* change background and tip color to yellow */
.speed-popup .mapboxgl-popup-content {
  background-color: transparent;
  border-color: none;
  box-shadow: none !important;
  cursor: default;
}

.speed-popup .mapboxgl-popup-tip {
  border-top-color: transparent;
  border: 0px;
}

.speed-popup .mapboxgl-popup-anchor-left {
  border-top-color: transparent;
}

.overspeed {
  color: red;
  font-size: 14px;
  position: relative;
  top: 2px;
}

.react-datepicker__time-list-item {
  padding: 0px !important;
}

.overlay-content {
  .overlay-close {
    z-index: 1;
  }
}

.pin {
  &.destination,
  &.warehouse,
  &.departure,
  &.detour {
    color: white;
    @include border-radius(50%);
    text-align: center;
    font-weight: bold;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
}

.layout-switch.btn-group {
  > .btn {
    box-shadow: none !important;

    &:first-child {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    &:last-child {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
}
.vehicle-color-container {
  width: 36px;
  height: 14px;
  @include border-radius('2px');
}

.max-speed {
  background-color: red;
  padding: 8px;
  border-radius: 5px;
}

.map-pin-popup {
  font-size: 10px;

  .popup-row {
    i {
      min-width: 16px;
    }
  }
}

.driver-path {
  min-height: 300px;
}

.modal {
  .modal-content.modal-invoice {
    background-color: transparent;
    border: 0;
  }
}

.table.order-list {
  th,
  td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 60px;
  }

  tbody tr td {
    background-color: inherit;
  }

  tbody tr td:first-child {
    padding-left: 18px;
  }

  th {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.blink {
  -webkit-animation: blink 0.5s steps(5, start) infinite;
  -moz-animation: blink 0.5s steps(5, start) infinite;
  animation: blink 0.5s steps(5, start) infinite;
}

@-webkit-keyframes blink {
  to {
    visibility: hidden;
  }
}

@-moz-keyframes blink {
  to {
    visibility: hidden;
  }
}

@keyframes blink {
  to {
    visibility: hidden;
  }
}

.PhoneInputInput {
  border: none;
}

.PhoneInputCountryIcon--border {
  background-color: transparent !important;
  box-shadow: none !important;
}

.PhoneInputCountryIcon {
  min-height: 2em;
  min-width: 2em;
}

.square-xs {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 12px;
  border-radius: 5px;
}

.overlay-loader.active {
  position: relative;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.9;
    background: #fff;
    z-index: 1;
  }

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
  }
}

.instructions {
  background-color: #fafafa;
  padding: 10px;
  p {
    line-height: 1px;
  }

  .list-wrapper,
  .instruction-header-information {
    .distance,
    .duration {
      font-size: 20px;
      color: #2c2c2c;
    }
  }
  .instructions-miles {
    margin-left: 45px;
    position: relative;
    top: -15px;
    font-size: 12px;
    color: #78849e;
  }

  span {
    position: relative;
    top: -10px;
    left: 7px;
    color: #2c2c2c;
    font-size: 16px;
  }
}

// Class to remove sidebar when needed
body.no-sidebar {
  > #root {
    > .page-sidebar {
      display: none;
    }
    > .page-container {
      &,
      > .header {
        padding-left: 0;
      }
    }
  }
}

.error-container {
  margin-top: -100px;
  width: 38%;

  .error-icon {
    font-size: 120px;
    line-height: 120px;
  }

  .error-number {
    font-size: 60px;
    line-height: 60px;
  }
}

.table-input {
  min-width: 200px;

  &:read-only {
    color: #2c2c2c;
  }
}

.table-btn.disabled {
  opacity: 0.1;
  background-color: transparent;
  color: #626262;

  &:hover {
    border: 1px solid #f0f0f0;
  }
}

// Settings Modal
.settings-dialog {
  @include md-and-down {
    width: 700px !important;
    max-width: 700px !important;
  }

  .modal-header {
    display: none;
  }

  @include sm-and-down {
    width: auto !important;
    max-width: 600px !important;

    .modal-header {
      display: block;
      padding: 25px 16px 0 16px;
    }

    .modal-body {
      padding: 0;
    }
  }

  .settings-content {
    @include xs {
      .settings .nav-link .subtitle {
        display: none;
      }
    }

    @include sm-and-down {
      flex-direction: column !important;

      .settings {
        margin-left: 0;

        .nav-link {
          padding: 6px 16px;

          p {
            margin: 0;
          }

          .subtitle {
            display: none;
          }
        }
      }

      .tab-content {
        border-left: none;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 10px 0 0;
      }
    }
  }
}

.settings-dialog {
  margin-top: 80px;
}

.MuiChip-root {
  height: 24px !important;
}

.MuiOutlinedInput-input {
  .MuiInputBase-inputSizeSmall {
    padding: 7.5px 14px;
  }
}

.helper-text {
  font-size: 12px;
  color: #78849e;
}