.dropdown-container {
    .dropdown {
        .dropdown-button {
            background: transparent;
            border: none;
            color: white;
        }
        .dropdown-options {
            padding: 0px;
            button {
                padding: 0px 10px;
            }
        }
    }
}
